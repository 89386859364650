import * as sdk from 'matrix-js-sdk';

export const isUserAvailable = async (baseUrl, username) => {
    const client = sdk.createClient({
        baseUrl
    });
    return await client.isUsernameAvailable(username);
}

export const registerByPassword = async (baseUrl, credentials) => {
    const client = sdk.createClient({
        baseUrl
    });
    client
        .registerRequest(credentials)
        .catch(async (e) => {
            if (e.httpStatus === 401) {
                const response = await processFlows(
                    client,
                    e.data,
                    credentials
                );
            }
        });
}

export const processFlows = async (client, data, credentials) => {
    for (const f of data.flows) {
        for (const s of f.stages) {
            if (s === 'm.login.dummy') {
                return await processPasswordFlow(client, data, credentials);
            }
        }
    }
}

export const processPasswordFlow = async (client, data, credentials) => {
    let auth = {
        session: data.session,
        type: 'm.login.dummy',
    };

    return await client.registerRequest({
        username: credentials.username,
        password: credentials.password,
        auth,
    });
}