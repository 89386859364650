import {createReducer} from '@reduxjs/toolkit'
import {
    clearRegisterAction,
    orgAdminLoginAction,
    passwordLoginAction
} from '../control/RegisterControl.js'

const initialState = {
    password: {
        user: null,
        password: '',
        password_repeat: '',
    },
    org_admin: {
        first_name: null,
        last_name: null,
        response: null
    }
}

export const register = createReducer(initialState, (builder) => {
    builder.addCase(clearRegisterAction, (state) => {
        state.password = initialState.password;
        state.org_admin = initialState.org_admin;
    }).addCase(passwordLoginAction, (state, {payload}) => {
        state.password[payload.name] = payload.value
    }).addCase(orgAdminLoginAction, (state, {payload}) => {
        state.org_admin[payload.name] = payload.value
    });
});
