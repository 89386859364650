import {html, nothing} from 'lit-html';
import BElement from '../../BElement.js';
import OrgAdminUsersClient from '../control/OrgAdminUsersClient.js';
import {setUserEditState, setUserEditValueState} from '../control/OrgAdminControl.js';
import {addErrorMessage, addInformationMessage} from '../../messages/control/MessagesControl.js';


class OrgAdminUsersEdit extends BElement {
    connectedCallback() {
        super.connectedCallback();
        new OrgAdminUsersClient(this.state.matrix.base_url, this.state.matrix.session.access_token)
            .getMatrixUser(this.location.params.id)
            .then(r => setUserEditState(r[0]));
    }

    extractState(state) {
        return {
            matrix: state.matrix,
            user_edit: state.orgAdmin.user_edit
        }
    }

    view() {
        if (!this.state.user_edit) {
            return nothing;
        }

        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>${this.location.params.id}</h2>
                    <div class='inner'>
                        <h2>Details</h2>
                        <kosyma-input-text
                                identifier='username'
                                label='Username'
                                name='username'
                                autofocus='true'
                                value=${this.state.user_edit.username}
                                @keyup=${(e) => this.onChangeValue(e)}
                        >
                        </kosyma-input-text>
                    </div>

                    <kosyma-button
                            identifier='save'
                            label='Save'
                            @click=${this.onSave}
                    ></kosyma-button>

                    <h2>Devices</h2>
                    <table>
                        <thead>
                        <tr>
                            <th>Device-ID</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        ${this.state.user_edit.devices.map(e => html`
                            <tr>
                                <td>${e.device_id}</td>
                                <td>
                                    <kosyma-button
                                            identifier='delete'
                                            label='Delete'
                                            disabled='true'
                                    ></kosyma-button>
                                </td>
                            </tr>
                        `)}
                        </tbody>
                    </table>
                </div>
            </div>
        `
    }

    onChangeValue = ({target: {name, value}}) => {
        setUserEditValueState({name, value})
    }

    onSave = () => {
        new OrgAdminUsersClient(this.state.matrix.base_url, this.state.matrix.session.access_token)
            .updateUser(this.location.params.id, this.state.user_edit)
            .then(r => addInformationMessage('Update successfully')).catch(err => addErrorMessage(err));
    }
}

customElements.define('kosyma-orgadmin-users-edit', OrgAdminUsersEdit);
