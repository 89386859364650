import {createAction} from '@reduxjs/toolkit'
import store from '../../store.js'

export const resetSecurityCheckAction = createAction('resetSecurityCheckAction')
export const resetSecurityCheckState = state => {
    store.dispatch(resetSecurityCheckAction(state));
}

export const setSecurityCheckAction = createAction('setSecurityCheckAction')
export const setSecurityCheckState = state => {
    store.dispatch(setSecurityCheckAction(state));
}

