import {html} from 'lit-html';
import BElement from '../../BElement';
import {setDeviceVerificationState} from '../control/DevicesControl.js';

class DeviceVerificationReceiver extends BElement {
    connectedCallback() {
        super.connectedCallback();
        setDeviceVerificationState({name: 'request_state', value: null});
        setDeviceVerificationState({name: 'saas_emoji', value: null});
    }

    extractState(state) {
        return {
            verification: state.devices.verification,
        }
    }

    view() {
        const verificationRequest = globalThis.verificationRequests[0];
        if (this.state.verification.request_state === null) {
            return html`
                <div class='content-area'>
                    <div class='inner'>
                        <h2>Verify Device Request by ${verificationRequest.requestingUserId}</h2>
                        <div>
                            A request has been sent to verify your device.
                        </div>
                        <div>Please click 'Accept Request' to proceed with the
                            verification process and ensure your device is successfully authenticated.
                        </div>
                        <kosyma-button
                                identifier='Accept'
                                label='Accept Request'
                                @click=${this.onAcceptVerificationRequest}
                        ></kosyma-button>
                    </div>
                </div>
            `;
        }
        if (this.state.verification.request_state === 'show_sas') {
            return html`
                <div class='content-area'>
                    <div class='inner'>
                        <h2>Verify Device Request by ${verificationRequest.requestingUserId}</h2>
                        <div>
                            Compare the Emojis
                        </div>
                        <div>
                            ${this.state.verification.emoji}
                        </div>
                        <div>
                            ${this.state.verification.saas_short_auth_string}
                        </div>
                        <kosyma-button
                                identifier='OK'
                                label='OK'
                                @click=${() => verificationRequest.confirm()}
                        ></kosyma-button>
                    </div>
                </div>`
        }
        if (this.state.verification.request_state === 'cancel') {
            return html`
                <div class='content-area'>
                    <div class='inner'>
                        <h2>Verify Device Request by ${verificationRequest.requestingUserId}</h2>
                        <div>
                            Cancelled
                        </div>
                    </div>
                </div>`
        }
    }

    onAcceptVerificationRequest = async () => {
        for (const verificationRequest of globalThis.verificationRequests) {
            verificationRequest.accept();

            const sas = await verificationRequest.startVerification('m.sas.v1');
            console.log('SAS-Verifikation gestartet');

            sas.on('show_sas', sasEvent => {
                console.log('SAS-Code erhalten (Empfänger):', sasEvent.sas.emoji);
                setDeviceVerificationState({name: 'request_state', value: 'show_sas'});
                setDeviceVerificationState({name: 'saas_emoji', value: sasEvent.emoji});
                setDeviceVerificationState({name: 'saas_short_auth_string', value: sasEvent.short_auth_string});
            });

            sas.on('cancel', reason => {
                setDeviceVerificationState({name: 'request_state', value: 'cancel'});
                console.error('Verifizierung abgebrochen:', reason);
            });

            // Abfangen von Verifikationsfehlern oder Abbrüchen
            verificationRequest.on('cancel', error => {
                console.error('Verifikation wurde abgebrochen:', error);
            });
        }
    }
}

customElements.define('kosyma-device-verification-receiver', DeviceVerificationReceiver);