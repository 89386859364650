import BElement from '../../BElement.js'
import {html} from 'lit-html'
import '../css/SecurityCheck.css'
import {resetSecurityCheckState, setSecurityCheckState} from '../control/SecurityCheckControl.js';

class SecurityCheckPassword extends BElement {
    constructor() {
        super();
        this.onContinue = () => {
        };
        this.onCancel = () => {
        };
    }

    extractState(state) {
        return {
            password: state.securityCheck.password,
        }
    }

    view() {
        const label = this.getAttribute('text')

        return html`
            <div class='securityCheck'>
                <div class='content-area'>
                    <div class='inner'>
                        <h2>${label}</h2>
                        <kosyma-input-text
                                identifier='password'
                                label='Password'
                                name='password'
                                type='password'
                                autofocus='true'
                                value=${this.state.password}
                                @keyup=${e => this.onChangeValue(e)}
                        ></kosyma-input-text>
                        <kosyma-button
                                identifier='continue'
                                label='Continue'
                                @click=${() => {
                                    this.onContinue(this.state.password);
                                    resetSecurityCheckState();
                                }}
                        ></kosyma-button>
                        <kosyma-button
                                identifier='cancel'
                                label='Cancel'
                                @click=${() => {
                                    resetSecurityCheckState();
                                    this.onCancel();
                                }}
                        ></kosyma-button>
                    </div>
                </div>
            </div>
        `
    }

    onChangeValue = ({target: {name, value}}) => {
        setSecurityCheckState({name, value})
    }
}

customElements.define('kosyma-securitycheck-password', SecurityCheckPassword)
