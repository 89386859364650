import {html} from 'lit-html';
import BElement from '../../BElement.js';
import '../css/Home.css';

class Home extends BElement {
    extractState(state) {
        return {
            matrix: state.matrix
        }
    }

    view() {
        return html`
            <div class='main-content'>
                <div class='header'>
                    <h1>Welcome ${this.state.matrix.session.user_id}</h1>
                </div>
                <div class='cards'>
                    <kosyma-home-invitations></kosyma-home-invitations>
                </div>
                <div class='cards'>
                    <kosyma-notifications></kosyma-notifications>
                </div>
            </div>`
    }
}

customElements.define('kosyma-home', Home);
