import {html, nothing} from 'lit-html'
import {Router} from '@vaadin/router'
import BElement from '../../BElement.js'
import {getMatrixClient} from '../../matrix/control/MatrixClient.js'
import {setDeviceListState} from '../control/DevicesControl.js'
import {addInformationMessage} from '../../messages/control/MessagesControl.js';

class DeviceList extends BElement {
    connectedCallback() {
        super.connectedCallback();
        this.updateDeviceList();
    }

    extractState(state) {
        return {
            device_id: state.matrix.session.device_id,
            list: state.devices.list,
            matrix: state.matrix
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>Devices</h2>
                    <table>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>User</th>
                            <th>Verified</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        ${this.state.list.results.map(e => html`
                            <tr>
                                <td>${e.device_id}</td>
                                <td>${e.display_name}</td>
                                <td>${e.user_id}</td>
                                <td>${e.is_verified}</td>
                                <td>
                                    ${e.device_id !== this.state.matrix.session.device_id ?
                                            html`
                                                <kosyma-button
                                                        identifier='delete'
                                                        label='Delete'
                                                        @click=${() => setDeviceListState({
                                                            name: 'delete_id',
                                                            value: e.device_id
                                                        })}
                                                ></kosyma-button>
                                            `
                                            : nothing}
                                    <kosyma-button
                                            identifier='verify'
                                            label='Verify'
                                            @click=${() => this.onVerify(e.device_id)}
                                    ></kosyma-button>
                                </td>
                            </tr>
                        `)}
                        </tbody>
                    </table>
                    ${this.state.list.delete_id ? html`
                        <kosyma-securitycheck-password
                                text='Delete Device ${this.state.list.delete_id}'
                                .onCancel=${() => setDeviceListState({name: 'delete_id', value: null})}
                                .onContinue=${password => {
                                    this.onDelete(this.state.list.delete_id, password).then(res => console.log(res));
                                }}
                        ></kosyma-securitycheck-password>` : nothing}
                </div>
            </div>
        `
    }

    onVerify = deviceId => {
        Router.go('/messenger/devices/verification/sender/' + deviceId + '/send')
    }

    onDelete = async (deviceId, password) => {
        try {
            await this.deleteDevice(deviceId, {});
            this.updateDeviceList();
        } catch (err) {
            if (err.httpStatus === 401 && err.data.flows) {
                try {
                    const authObject = this.createAuthObject(err.data.session, password);
                    await this.deleteDevice(deviceId, authObject);
                    setDeviceListState({name: 'delete_id', value: null});
                    addInformationMessage('Device ' + deviceId  + ' successfully deleted!');
                    this.updateDeviceList();
                } catch (authError) {
                    this.addErrorMessage(authError);
                }
            } else {
                this.addErrorMessage(err);
            }
        }
    }

    deleteDevice = (deviceId, authObject) => {
        return getMatrixClient().deleteMultipleDevices([deviceId], authObject);
    }

    updateDeviceList = async () => {
        const results = await getMatrixClient().getDevices();
        setDeviceListState({name: 'results', value: results.devices.reverse()});
    }

    createAuthObject = (session, password) => {
        return {
            type: 'm.login.password',
            session: session,
            identifier: {
                type: 'm.id.user',
                user: getMatrixClient().getUserId(),
            },
            password: password,
        };
    }
}

customElements.define('kosyma-device-list', DeviceList)