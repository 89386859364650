import * as sdk from 'matrix-js-sdk';
import {cryptoCallbacks} from './SecretStorageKeys.js';

let client;

export const createMatrixClient = async (loginResponse, baseUrl) => {
    await deleteLocalStorage();

    const indexedDBStore = new sdk.IndexedDBStore({
        indexedDB: globalThis.indexedDB,
        localStorage: globalThis.localStorage,
        dbName: 'kosyma-ti-messenger'
    });

    await indexedDBStore.startup();

    //var loginResponse.well_known['m.homeserver'].base_url;
    client = sdk.createClient({
        baseUrl: baseUrl,
        accessToken: loginResponse.access_token,
        deviceId: loginResponse.device_id,
        userId: loginResponse.user_id,
        store: indexedDBStore,
        cryptoStore: new sdk.IndexedDBCryptoStore(globalThis.indexedDB, 'crypto'),
        timelineSupport: true,
        verificationMethods: [
            'm.sas.v1',
        ],
        cryptoCallbacks
    });

    await client.initCrypto();
}

export const deleteLocalStorage = async _ => {
    window.localStorage.clear();
    if (window.indexedDB.databases !== undefined) {
        const dbs = await window.indexedDB.databases();
        dbs.forEach(db => window.indexedDB.deleteDatabase(db.name));
    }
}

export const getMatrixClient = () => {
    return client;
}