import {html, nothing} from 'lit-html';
import BElement from '../../BElement.js';
import '../css/RoomChat.css';
import {setRoomChatState} from '../control/RoomControl.js';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';

class RoomChatMessage extends BElement {
    extractState(state) {
        return {
            matrix: state.matrix,
            chat: state.rooms.room.chat
        }
    }

    view() {
        const event = JSON.parse(this.getAttribute('event'));

        const content = event.type === 'm.room.message' ? event.content.body : null;
        const url = this.getDownloadUrl(event);

        if (event.type === 'm.room.encrypted') {
            getMatrixClient().crypto.decryptEvent(event).then(e => {
                debugger
                console.log(e);
            })
        }

        return html`
            <div class='message user'>
                <div class='message header container'>
                    <div class='message header left'>
                        <kosyma-initials-circle name=${event.sender}></kosyma-initials-circle>
                    </div>
                    <div class='message header right'>
                        ${event.type}
                        ${event.type === 'm.room.message' ? html`
                            <kosyma-icon icon='reply'
                                         @click=${() => this.onReply(event.event_id)}></kosyma-icon>` : nothing}
                        <kosyma-icon icon='file-code' @click=${() => this.onShowCode(event.event_id)}></kosyma-icon>
                    </div>
                </div>

                ${this.state.chat.show_code.indexOf(event.event_id) !== -1 ? html`
                    <pre class='message code'>${JSON.stringify(event, null, 1)}</pre>
                ` : nothing}

                ${url
                        ? html`<img src=${url} style='max-width: 80%'>`
                        : nothing}

                ${url ? html`
                            <div>
                                <a @click=${() => window.open(url)}>Download</a>
                            </div>`
                        : nothing}

                ${content && !url ? html`
                    <div>
                        ${content}
                    </div>
                ` : nothing}

                ${this.state.chat.reply.indexOf(event.event_id) !== -1 ? html`
                    <div class='message code'>
                        <kosyma-room-chat-reply event=${JSON.stringify(event)}></kosyma-room-chat-reply>
                    </div>
                ` : nothing}
            </div>
        `;
    }

    getDownloadUrl = event => {
        let mxcUrl = null;
        if (event.type === 'm.room.message') {
            mxcUrl = event.content.url;
        } else if (event.type === 'm.room.encrypted') {
            mxcUrl = event.clearEvent?.content?.url;
        }
        if (mxcUrl) {
            return getMatrixClient().mxcUrlToHttp(mxcUrl);
        }
        return null;
    }

    onReply = eventId => {
        const reply = [...this.state.chat.reply];
        const index = reply.indexOf(eventId);
        if (index !== -1) {
            reply.splice(index, 1);
        } else {
            reply.push(eventId);
        }
        setRoomChatState({
            name: 'reply',
            value: reply
        });
    }

    onShowCode = eventId => {
        const show_code = [...this.state.chat.show_code];
        const index = show_code.indexOf(eventId);
        if (index !== -1) {
            show_code.splice(index, 1);
        } else {
            show_code.push(eventId);
        }
        setRoomChatState({
            name: 'show_code',
            value: show_code
        });
    }
}

customElements.define('kosyma-room-chat-messsage', RoomChatMessage);