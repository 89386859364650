import {html, nothing} from 'lit-html';
import BElement from '../../BElement.js';
import {Router} from '@vaadin/router';
import '../css/notification.css'
import {removeNotificationState} from '../control/NotificationsControl.js';


class Notifications extends BElement {
    extractState(state) {
        return {
            notifications: state.notifications
        }
    }

    view() {
        if (this.state.notifications.notifications.length === 0) {
            return nothing;
        }

        return html`
            <div class='notification-wrapper'>
                ${this.state.notifications.notifications.map(m => this.renderNotification(m))}
            </div>
        `
    }

    renderNotification = notification => {
        return html`
            <div class='notification-content'>
                <span class='notification-title'>${notification.title}</span>
                <span class='notification-text'>${notification.text}</span>
                <span class='notification-link'
                      @click=${() => this.onOpenLink(notification)}>${notification.link}</span>
                <button class='close-button' @click=${() => this.onRemoveNotification(notification)}>✖</button>
            </div>
        `;
    }

    onRemoveNotification = notification => {
        removeNotificationState([notification]);
    }

    onOpenLink = notification => {
        Router.go(notification.link);
    }
}

customElements.define('kosyma-notifications', Notifications);
