import {createReducer} from '@reduxjs/toolkit'
import {resetSecurityCheckAction, setSecurityCheckAction} from '../control/SecurityCheckControl.js';

const initialState = {
    password: null
}
export const securityCheck = createReducer(initialState, (builder) => {
    builder.addCase(resetSecurityCheckAction, (state) => {
        state.password = null;
    }).addCase(setSecurityCheckAction, (state, {payload}) => {
        state[payload.name] = payload.value;
    });
})
