import {createReducer} from '@reduxjs/toolkit'
import {userEditAction, userEditActionValue, userListAction} from '../control/OrgAdminControl.js'

const initialState = {
    user_list: [],
    user_edit: null,
}

export const orgAdmin = createReducer(initialState, (builder) => {
    builder.addCase(userListAction, (state, {payload}) => {
        state.user_list = payload;
    }).addCase(userEditAction, (state, {payload}) => {
        state.user_edit = payload;
    }).addCase(userEditActionValue, (state, {payload}) => {
        state.user_edit[payload.name] = payload.value;
    })
});
