import {html} from 'lit-html'
import {Router} from '@vaadin/router'
import BElement from '../../BElement.js'


class LoginByOrgAdmin extends BElement {
    extractState(state) {
        return {
            base_url: state.matrix.base_url,
            server_type: state.matrix.server_type,
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>Login at ${this.state.base_url}</h2>
                    <div>The backend service is currently running in "Gematik" mode. To log in, you will be automatically redirected to Cognito. 
                        There, you can enter your credentials and securely access the desired features. This process ensures a smooth and safe user experience. 
                        If you have any further questions about the login process, please feel free to reach out!</div>
                    <kosyma-button
                            identifier='sso'
                            label='Redirect to SSO'
                            @click=${() => this.onSsoRedirect()}
                    ></kosyma-button>
                    <div id='register-gematik' class='link' @click=${() => Router.go('/register')}>
                        Don't
                        have an
                        account yet?
                        Register
                    </div>
                    <div id='change-server' class='link' @click=${() => Router.go('/change-server')}>Change
                        Server
                    </div>
                </div>
            </div>
        `
    }

    onSsoRedirect = () => {
        const redirectUrl = location.protocol + '//' + location.host
        window.location = this.state.base_url + '/_matrix/client/v3/login/sso/redirect/cognito?redirectUrl=' + redirectUrl
    }
}

customElements.define('kosyma-login-orgadmin', LoginByOrgAdmin)
