import {createReducer} from '@reduxjs/toolkit'
import {
    addNotificationAction,
    removeNotificationAction
} from '../control/NotificationsControl.js'

const initialState = {
    notifications: [{title: 'Sample', text: 'Test', link: '/messenger/rooms/'}],
}
export const notifications = createReducer(initialState, (builder) => {
    builder.addCase(addNotificationAction, (state, {payload}) => {
        state.notifications.push(payload);
    }).addCase(removeNotificationAction, (state, {payload}) => {
        payload.forEach(m => {
            state.notifications = state.notifications.filter(item => item.id !== m.id);
        })
    });
})
