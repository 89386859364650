import {html, nothing} from 'lit-html';
import BElement from '../../BElement.js';
import '../css/tab.css';
import {setRoomState} from '../control/RoomControl.js';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';

class Room extends BElement {
    connectedCallback() {
        super.connectedCallback();
        const room = getMatrixClient().getRoom(this.location.params.roomId);
        setRoomState({name: 'id', value: this.location.params.roomId});
        setRoomState({name: 'name', value: room.name});
    }

    extractState(state) {
        return {
            room: state.rooms.room,
            matrix_mode: state.matrix.matrix_mode
        }
    }

    view() {
        if (!this.state.room.id) {
            return nothing;
        }
        return html`
            <h1>Room ${this.state.room.name}</h1>
            <div class='tabs'>
                <div class='tab' ?selected=${this.state.room.selected_tab === 0} @click=${() => this.selectTab(0)}>
                    Chat
                </div>
                <div class='tab' ?selected=${this.state.room.selected_tab === 1} @click=${() => this.selectTab(1)}>
                    Members
                </div>
                <div class='tab' ?selected=${this.state.room.selected_tab === 2} @click=${() => this.selectTab(2)}>
                    Invitation
                </div>
                ${this.state.matrix_mode === 'gematik' ? html`
                    <div class='tab' ?selected=${this.state.room.selected_tab === 3} @click=${() => this.selectTab(3)}>
                        VZD
                    </div>
                ` : nothing}
                <div class='tab' ?selected=${this.state.room.selected_tab === 4} @click=${() => this.selectTab(4)}>
                    Admin
                </div>
            </div>
            <div class='tab-content'>
                ${this.state.room.selected_tab === 0 ? html`
                    <kosyma-room-chat roomId=${this.state.room.id}></kosyma-room-chat>` : nothing}
                ${this.state.room.selected_tab === 1 ? html`
                    <kosyma-room-members roomId=${this.state.room.id}></kosyma-room-members>` : nothing}
                ${this.state.room.selected_tab === 2 ? html`
                    <kosyma-room-invitation roomId=${this.state.room.id}></kosyma-room-invitation>` : nothing}
                ${this.state.room.selected_tab === 3 ? html`
                    <kosyma-room-vzd-search roomId=${this.state.room.id}></kosyma-room-vzd-search>` : nothing}
                ${this.state.room.selected_tab === 4 ? html`
                    <kosyma-room-admin roomId=${this.state.room.id}></kosyma-room-admin>` : nothing}
            </div>
        `;
    }

    selectTab = index => {
        setRoomState({name: 'selected_tab', value: index});
    }
}

customElements.define('kosyma-room', Room);