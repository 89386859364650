import {createAction} from '@reduxjs/toolkit'
import store from '../../store.js'

export const userListAction = createAction('userListAction')
export const setUserListState = state => {
    store.dispatch(userListAction(state))
}

export const orgAdminAction = createAction('orgAdminAction')
export const setOrgAdminState = state => {
    store.dispatch(orgAdminAction(state));
}

export const userEditAction = createAction('userEditAction')
export const setUserEditState = state => {
    store.dispatch(userEditAction(state))
}
export const userEditActionValue = createAction('userEditActionValue')
export const setUserEditValueState = state => {
    store.dispatch(userEditActionValue(state))
}