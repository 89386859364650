import BElement from '../../BElement.js'
import {getMatrixMode} from '../control/LoginClient.js'
import {setMatrixState} from '../../matrix/control/MatrixControl.js';
import {html} from 'lit-html';


class Login extends BElement {
    connectedCallback() {
        super.connectedCallback();
        getMatrixMode(this.state.base_url).then(res => setMatrixState({
            name: 'matrix_mode',
            value: res
        })).catch(err => console.log(err));
    }

    extractState(state) {
        return {
            base_url: state.matrix.base_url,
            matrix_mode: state.matrix.matrix_mode
        }
    }

    view() {
        if (this.state.matrix_mode === null) {
            return html`
                <kosyma-login-detect-backend></kosyma-login-detect-backend>`;
        } else if (this.state.matrix_mode === 'matrix') {
            return html`
                <kosyma-login-password></kosyma-login-password>`;
        } else if (this.state.matrix_mode === 'gematik') {
            return html`
                <kosyma-login-orgadmin></kosyma-login-orgadmin>`;
        } else {
            return html`
                <kosyma-login-unknown></kosyma-login-unknown>`;
        }
    }
}

customElements.define('kosyma-login', Login)
