import {html} from 'lit-html';
import BElement from '../../BElement';

class DeviceVerificationError extends BElement {
    extractState(state) {
        return {
            verification: state.devices.verification,
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>1 Device ${this.state.verification.device_id}</h2>
                    <div>
                        Error 
                    </div>
                </div>
            </div>
        `;
    }

}

customElements.define('kosyma-device-verification-error', DeviceVerificationError);