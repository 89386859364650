import {html} from 'lit-html'
import BElement from '../../BElement.js'

class LoginUnknownServer extends BElement {
    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>Unknown Server</h2>
                </div>
            </div>
        `
    }
}

customElements.define('kosyma-login-unknown-server', LoginUnknownServer)
