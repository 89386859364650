import {html} from 'lit-html'
import {Router} from '@vaadin/router'
import BElement from '../../BElement.js'
import {loginByPassword} from '../control/LoginClient.js'
import {setPasswordLoginState} from '../control/LoginControl.js'
import {setMatrixUpdateState, setSessionState} from '../../matrix/control/MatrixControl.js'
import {createMatrixClient} from '../../matrix/control/MatrixClient.js'
import {addErrorMessage} from '../../messages/control/MessagesControl.js'


class LoginByPassword extends BElement {
    extractState(state) {
        return {
            base_url: state.matrix.base_url,
            server_type: state.matrix.server_type,
            user: state.login.m_password.user,
            password: state.login.m_password.password
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>Login at ${this.state.base_url}</h2>
                    <kosyma-input-text
                            identifier='username'
                            label='Username'
                            name='user'
                            autofocus='true'
                            value=${this.state.user}
                            @keyup=${(e) => this.onChangeValue(e)}
                    >
                    </kosyma-input-text>
                    <kosyma-input-text
                            identifier='password'
                            label='Password'
                            name='password'
                            type='password'
                            value=${this.state.password}
                            @keyup=${e => this.onChangeValue(e)}
                    >
                    </kosyma-input-text>
                    <kosyma-button
                            identifier='login'
                            label='Login'
                            @click=${this.onLogin}
                    ></kosyma-button>
                    <div></div>
                    <div id='register-matrix' class='link' @click=${() => Router.go('/register')}>
                        Change
                        Don't
                        have an
                        account yet?
                        Register
                    </div>
                    <div id='change-server' class='link' @click=${() => Router.go('/change-server')}>Change
                        Server
                    </div>
                </div>
            </div>
        `
    }

    onChangeValue = ({target: {name, value}}) => {
        setPasswordLoginState({name, value})
    }

    onLogin = _ => {
        loginByPassword(this.state.base_url, this.state.user, this.state.password)
            .then(res => {
                createMatrixClient(res, this.state.base_url)
                    .then(() => {
                        setSessionState(res)
                        setMatrixUpdateState()
                        Router.go('/messenger/')
                    })
            })
            .catch(err => {
                addErrorMessage('Login failed')
            })
    }
}

customElements.define('kosyma-login-password', LoginByPassword)
