import {createReducer} from '@reduxjs/toolkit'
import {passwordLoginAction} from '../control/LoginControl.js'

const initialState = {
    m_password: {
        user: 'demo1234',
        password: 'demo1234',
    }
}
export const login = createReducer(initialState, (builder) => {
    builder.addCase(passwordLoginAction, (state, {payload}) => {
        state.m_password[payload.name] = payload.value
    });
})
