import {html} from 'lit-html'
import BElement from '../../BElement.js'
import {setOrgAdminLoginState} from '../control/RegisterControl.js';
import {registerByOrgAdmin} from '../control/RegisterByOrgAdminClient.js';
import {Router} from '@vaadin/router';
import {addErrorMessage, addInformationMessage} from '../../messages/control/MessagesControl.js';
import {setPasswordLoginState} from '../../login/control/LoginControl.js';


class RegisterByOrgAdmin extends BElement {
    extractState(state) {
        return {
            baseUrl: state.matrix.base_url,
            org_admin: state.register.org_admin,
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>Register new User</h2>
                    <kosyma-input-text
                            identifier='firstname'
                            label='Firstname'
                            name='first_name'
                            value=${this.state.org_admin.first_name}
                            @keyup=${(e) => this.onChangeValue(e)}
                    >
                    </kosyma-input-text>
                    <kosyma-input-text
                            identifier='lastname'
                            label='Lastname'
                            name='last_name'
                            value=${this.state.org_admin.last_name}
                            @keyup=${(e) => this.onChangeValue(e)}
                    >
                    </kosyma-input-text>
                    <kosyma-button
                            identifier='register'
                            label='Register'
                            @click=${() => this.onRegister()}
                    ></kosyma-button>
                </div>

            </div>
        `
    }

    onChangeValue = ({target: {name, value}}) => {
        setOrgAdminLoginState({name, value})
    }

    onRegister = () => {
        registerByOrgAdmin(this.state.baseUrl, this.state.org_admin).then(async res => {
            if (res.status === 200) {
                setOrgAdminLoginState({name: 'response', value: res});
                Router.go('/login/');
                const body = await res.json();
                addInformationMessage('User ' + body.user_id + ' successfully registered!');
                setPasswordLoginState({name: 'user', value: body.user_id});
                setPasswordLoginState({name: 'password', value: 'Welcome123456789!'});
            } else {
                addErrorMessage('Server returned error ' + res.status + '!');
            }
        }).catch(err => {
            addErrorMessage(err);
        });
    }
}

customElements.define('kosyma-register-orgadmin', RegisterByOrgAdmin);
