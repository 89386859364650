import {createAction} from '@reduxjs/toolkit'
import store from '../../store.js'
import {generateUUID} from '../../UUIDGenerator.js';

export const addNotificationAction = createAction('addNotificationAction')
export const addNotificationState = state => {
    state.id = generateUUID();
    state.time = Date.now();
    store.dispatch(addNotificationAction(state))
}

export const removeNotificationAction = createAction('removeNotificationAction')
export const removeNotificationState = state => {
    store.dispatch(removeNotificationAction(state))
}

