import {html} from 'lit-html';
import BElement from '../../BElement';
import {setDeviceVerificationState} from '../control/DevicesControl.js';

class DeviceVerificationSenderStart extends BElement {
    extractState(state) {
        return {
            verification: state.devices.verification,
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>Step 1 Device ${this.state.verification.device_id}</h2>
                    <div>
                        Welcome,
                    </div>

                </div>
            </div>
        `;
    }

    onStartVerification = async () => {
        for (const verificationRequest of globalThis.verificationEvents) {
            const hasVerificationReadyEvent = [...verificationRequest.eventsByThem.keys()]
                .some(eventByThem => eventByThem === 'm.key.verification.ready');

            if (hasVerificationReadyEvent) {
                const verifier = await verificationRequest.startVerification('m.sas.v1');
            }
        }
    }
}

customElements.define('kosyma-device-verification-sender-start', DeviceVerificationSenderStart);