import {html, nothing} from 'lit-html';
import BElement from '../../BElement';
import {setDeviceVerificationState} from '../control/DevicesControl.js';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';

class DeviceVerificationSenderSender extends BElement {
    connectedCallback() {
        super.connectedCallback();
        setDeviceVerificationState({name: 'device_id', value: this.location.params.deviceId});
        setDeviceVerificationState({name: 'request_state', value: null});
        setDeviceVerificationState({name: 'saas_emoji', value: null});
        setDeviceVerificationState({name: 'saas_short_auth_string', value: null});
    }

    extractState(state) {
        return {
            verification: state.devices.verification,
        }
    }

    view() {
        if (this.state.verification.request_state === null) {
            return html`
                <div class='content-area'>
                    <div class='inner'>
                        <h2>Verify Device ${this.state.verification.device_id}</h2>
                        <div>
                            With this, you are initiating the verification process, which consists of several steps. To
                            begin, simply click 'Send Request'.
                        </div>
                        <kosyma-button
                                identifier='Send'
                                label='Send Request'
                                @click=${this.onSendVerificationRequest}
                        ></kosyma-button>
                    </div>
                </div>`
        } else if (this.state.verification.request_state === 'ready') {
            return html`
                <div class='content-area'>
                    <div class='inner'>
                        <h2>Verify Device ${this.state.verification.device_id}</h2>
                        <div>
                            With this, you are initiating the verification process, which consists of several steps. To
                            begin, simply click 'Send Request'.
                        </div>
                        <div>
                            ${this.state.verification.emoji}
                        </div>
                        <div>
                            ${this.state.verification.saas_short_auth_string}
                        </div>
                        <kosyma-button
                                identifier='OK'
                                label='OK'
                                @click=${this.onSendVerificationRequest}
                        ></kosyma-button>
                    </div>
                </div>`
        } else if (this.state.verification.request_state === 'done') {

        } else if (this.state.verification.request_state === 'cancelled') {

        }
    }

    onSendVerificationRequest = async () => {
        const verification = await getMatrixClient().getCrypto()
            .requestDeviceVerification(getMatrixClient().getUserId(), this.state.verification.device_id);

        verification.on('change', () => {
            console.log('Verifikationsstatus geändert:', verificationRequest);

            if (verification.ready && !verification.done && !verification.cancelled) {
                setDeviceVerificationState({name: 'request_state', value: 'ready'});
                console.log('Verifikation ist bereit.');
                this.startVerification(verification);
            }

            if (verification.done) {
                setDeviceVerificationState({name: 'request_state', value: 'done'});
                console.log('Verifikation abgeschlossen!');
            }

            if (verification.cancelled) {
                setDeviceVerificationState({name: 'request_state', value: 'cancelled'});
                console.log('Verifikation abgebrochen.');
            }
        });

        console.log('Verifikationsanfrage gesendet.');


        console.log('verification request', verification);
//        Router.go('/messenger/devices/verification/sender/' + this.state.verification.device_id + '/start')
    }

    // Verifikation (z.B. Emoji-vergleich) starten
    startVerification = async verification => {
        try {
            // Emoji-Phase starten
            const sas = await verification.startVerification('m.sas.v1');

            // Event-Listener für Emoji- oder Zahlenvergleiche
            sas.on('show_sas', (r) => {
                setDeviceVerificationState({name: 'saas_emoji', value: sas.emoji});
                setDeviceVerificationState({name: 'saas_short_auth_string', value: sas.short_auth_string});

                console.log('Verifikation Emoji-SAS Vergleich starten.');
                console.log('Vergleiche Emojis:', sas.emoji); // Emojis anzeigen, die verglichen werden sollen
                console.log('Vergleiche Zahlen:', sas.short_auth_string); // Alternative, falls Zahlen verwendet werden
            });

            // Verifikationsereignisse für die Statusänderungen
            sas.on('done', () => {
                console.log('Verifikation erfolgreich abgeschlossen!');
            });

            sas.on('cancel', () => {
                console.log('Verifikation abgebrochen.');
            });
        } catch (err) {
            console.error('Fehler beim Starten der Verifikation:', err);
        }
    }
}

customElements.define('kosyma-device-verification-sender', DeviceVerificationSenderSender);