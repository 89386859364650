import {html} from 'lit-html'
import BElement from '../../BElement.js'
import '../css/login.css'

class LoginDetectBackend extends BElement {
    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>Loading...</h2>
                    <div class='login-loader'></div>
                </div>
            </div>
        `
    }
}

customElements.define('kosyma-login-detect-backend', LoginDetectBackend)
