import {html} from 'lit-html'
import BElement from '../../BElement.js'

class Register extends BElement {
    extractState(state) {
        return {
            matrix_mode: state.matrix.matrix_mode
        }
    }

    view() {
        if (this.state.matrix_mode === null || this.state.matrix_mode === 'matrix') {
            return html`
                <kosyma-register-password></kosyma-register-password>`;
        } else {
            return html`
                <kosyma-register-orgadmin></kosyma-register-orgadmin>`;
        }
    }
}

customElements.define('kosyma-register', Register);
