import Olm from '@matrix-org/olm';
import {Router} from '@vaadin/router';
import store from './store.js';
import './form/boundary/Button.js';
import './form/boundary/InputText.js';
import './form/boundary/Checkbox.js';
import './form/boundary/Icon.js';
import './securitycheck/boundary/SecurityCheckPassword.js';
import './messages/boundary/Messages.js';
import './login/boundary/Login.js';
import './login/boundary/LoginDetectBackend.js';
import './login/boundary/LoginByPassword.js';
import './login/boundary/LoginUnknownServer.js';
import './login/boundary/LoginByOrgAdmin.js';
import './register/boundary/Register.js';
import './register/boundary/RegisterByAdminOrg.js';
import './register/boundary/RegisterByPassword.js';
import './room/boundary/RoomList.js';
import './room/boundary/RoomCreate.js';
import './home/boundary/Home.js';
import './home/boundary/Invitations.js';
import './footer/boundary/Footer.js';
import './header/boundary/Header.js';
import './matrix/boundary/Matrix.js';
import './matrix/boundary/ChangeServer.js';
import './content/boundary/Main.js';
import './content/boundary/Container.js';
import './content/boundary/Content.js';
import './room/boundary/Room.js';
import './room/boundary/RoomChat.js';
import './room/boundary/RoomInvitation.js';
import './room/boundary/RoomAdmin.js';
import './room/boundary/RoomMembers.js';
import './room/boundary/InitialsCircle.js';
import './room/boundary/RoomChatMessage.js';
import './room/boundary/RoomChatInputField.js';
import './room/boundary/RoomChatReply.js';
import './room/boundary/RoomVzdSearch.js';
import './devices/boundary/DeviceList.js';
import './devices/boundary/DeviceVerificationError.js';
import './devices/boundary/DeviceVerificationSenderSender.js';
import './devices/boundary/DeviceVerificationSenderStart.js';
import './devices/boundary/DeviceVerificationReceiver.js';
import './notification/boundary/Notifications.js';
import './orgadmin/boundary/OrgAdminMenu.js';
import './orgadmin/boundary/OrgAdminUsersList.js';
import './orgadmin/boundary/OrgAdminUsersEdit.js';
import {save} from './localstorage/control/StorageControl.js';
import {extractParamHref} from './AppParamExtractor.js';
import {loginByToken} from './login/control/LoginClient.js';
import {addErrorMessage} from './messages/control/MessagesControl.js';
import {setMatrixUpdateState, setSessionState} from './matrix/control/MatrixControl.js';
import {createMatrixClient} from './matrix/control/MatrixClient.js';

globalThis.Olm = Olm;

store.subscribe(_ => {
    const state = store.getState();
    save(state);
})

const sessionTest = async (context, commands) => {
    if (!store.getState().matrix.session) {
        return commands.redirect('/login');
    }
    return true;
}

const app = document.getElementById('outlet');
const router = new Router(app);
router.setRoutes([
    {
        path: '/',
        component: 'kosyma-login',
    },
    {
        path: '/login',
        component: 'kosyma-login',
    },
    {
        path: '/register',
        component: 'kosyma-register'
    },
    {
        path: '/messenger',
        action: sessionTest,
        children: [
            {path: '/', component: 'kosyma-home'},
            {path: '/rooms/', component: 'kosyma-room-list'},
            {path: '/rooms/create', component: 'kosyma-room-create'},
            {path: '/rooms/:roomId', component: 'kosyma-room'},
            {path: '/orgadmin/menu', component: 'kosyma-orgadmin-menu'},
            {path: '/orgadmin/users/list', component: 'kosyma-orgadmin-users-list'},
            {path: '/orgadmin/users/:id/edit', component: 'kosyma-orgadmin-users-edit'},
            {path: '/devices/list', component: 'kosyma-device-list'},
            {
                path: '/devices/verification/receiver/',
                component: 'kosyma-device-verification-receiver'
            },
            {
                path: '/devices/verification/sender/:deviceId/send',
                component: 'kosyma-device-verification-sender'
            },
            {
                path: '/devices/verification/sender/:deviceId/start',
                component: 'kosyma-device-verification-sender-start'
            },
            {path: '/devices/verification/sender/:deviceId/error', component: 'kosyma-device-verification-error'},
        ],
    },
    {path: '/change-server', component: 'kosyma-change-server'},
    {
        path: '(.*)',
        component: 'kosyma-login'
    },
]);

//--

const token = extractParamHref(location.href, 'loginToken');
if (token !== null) {
    loginByToken(store.getState().matrix.base_url, token).then(res => {
        setSessionState(res);
        createMatrixClient(res, store.getState().matrix.base_url)
            .then(r => {
                setMatrixUpdateState();
                Router.go('/messenger/')
            });
    }).catch(err => {
        addErrorMessage(err);
    });
}

//--
/*
(originalFetch => {
    window.fetch = (resource, init = {}) => {
        init.headers = init.headers || {};
        init.headers['X-TIM-User-Agent'] = agentIdentifier + ',' + window.navigator.userAgent;
        return originalFetch(resource, init);
    };
})(window.fetch);

*/